//==================================================
//= Settings                                     ===
//==================================================

//= Define Font ==========================//
//========================================//
$fontBody: 'NHaasGroteskTXPro-65Md', sans-serif;

//= Define Color =========================//
//========================================//
$color-1: #fbfafb !default;
$color-2: #f7f5f8 !default;
$color-3: #f2f0f4 !default;
$color-4: #e9e5ec !default;
$color-5: #ccc2d1 !default;

$color-6: #6a50a7 !default;
$color-7: #8067b7 !default;

$color-8: #1ebea5 !default;
$color-9: #1fbcd2 !default;

$color-10: #212121 !default;
$color-11: #616161 !default;
$color-12: #9e9e9e !default;

$color-13: #fc6f22 !default;
$color-14: #fdaa29 !default;
$color-15: #32adfc !default;

$color-16: #f5f2f5 !default;
$color-17: #f4f7f6 !default;
$color-18: #ddd6e0 !default;
$color-19: #8a8a8a !default;
$color-20: #baaec1 !default;
$color-21: #faf8fa !default;
$color-22: #03a9f4 !default;
$color-23: #f9f9f9 !default;

$my-colors: (
        "color-1" : $color-1,
        "color-2" : $color-2,
        "color-3" : $color-3,
        "color-4" : $color-4,
        "color-5" : $color-5,
        "color-6" : $color-6,
        "color-7" : $color-7,
        "color-8" : $color-8,
        "color-9" : $color-9,
        "color-10" : $color-10,
        "color-11" : $color-11,
        "color-12" : $color-12,
        "color-13" : $color-13,
        "color-14" : $color-14,
        "color-15" : $color-15,
        "color-16" : $color-16,
        "color-17" : $color-17,
        "color-18" : $color-18,
        "color-19" : $color-19,
        "color-20" : $color-20,
        "color-21" : $color-21,
        "color-22" : $color-22,
        "color-23" : $color-23,
);

$social-colors: (
        "twitter": #00aced,
        "facebook": #3b5998,
        "googleplus": #dd4b39,
        "pinterest": #cb2027,
        "linkedin": #007bb6,
        "youtube": #b00,
        "vimeo": #1ab7ea,
        "tumblr": #32506d,
        "instagram": #bc2a8d,
        "flickr": #ff0084,
        "dribbble": #ea4c89,
        "quora": #a82400,
        "foursquare": #0072b1,
        "forrst": #5b9a68,
        "vk": #45668e,
        "wordpress": #21759b,
        "stumbleupon": #eb4823,
        "yahoo": #7b0099,
        "blogger": #fb8f3d,
        "soundcloud": #ff3a00,
);


// border

$border-radius: rem(15);