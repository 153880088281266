body#UIKit-body {
  background: my-colors('color-10');
  display: flex;
  align-items: center;
  justify-content: center;
  @include boxSize(100vw, 100vh);
}

$edit-widget-height: rem(50);
$edit-widget-border-color: #e1dee2;
$edit-widget-border-radius: rem(5);
$slip-button-color: #f0eef2;
$font-color: #665f69;
$action-bg-color: my-colors('color-1');
$action-active-color: my-colors('color-6');

.edit-widget {
  @include position(absolute);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  @include boxSize(100%, $edit-widget-height);
  //min-width: rem(500);
  max-width: rem(500);
  background: transparent;
  border-radius: $edit-widget-border-radius;

  &.slide-up-show {
    .elements-wrapper {
      #option-data {
        display: block;
      }
      .arrows {
        &.arrow-right {
          border-top-right-radius: 0;
        }
      }
    }
  }

  .slip-button {
    flex: 0 0 rem(24); //<'flex-grow'> <'flex-shrink'> <'flex-basis'>
    @include boxSize(rem(24), $edit-widget-height);
    background: url("../../assets/images/png/slip-button.png") $slip-button-color no-repeat center center/ rem(4) rem(30);
    cursor: move;
    border: rem(1) solid $edit-widget-border-color;
    border-top-left-radius: $edit-widget-border-radius;
    border-bottom-left-radius: $edit-widget-border-radius;
  }

  .elements-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    //border-top-right-radius: $edit-widget-border-radius;
    //border-bottom-right-radius: $edit-widget-border-radius;
    @include boxSize(100%, $edit-widget-height);
    @include position;

    #option-data {
      background: color('white');
      @include boxSize(calc(100% + #{rem(1)}), auto);
      @include position(absolute, auto, 0, $edit-widget-height, rem(-1), 1);
      border: rem(1) solid $edit-widget-border-color;
      border-bottom: none;
      border-top-left-radius: $edit-widget-border-radius;
      border-top-right-radius: $edit-widget-border-radius;
      padding: rem(20);
      display: none;
    }

    & > div {
      display: inline-flex;
      align-items: stretch;
      justify-content: center;
      font-size: rem(20);
      flex: 1 0 auto; //<'flex-grow'> <'flex-shrink'> <'flex-basis'>

      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 $edit-widget-height; //<'flex-grow'> <'flex-shrink'> <'flex-basis'>
        color: $font-color;
        @include no-selection;
        @include transition(color .2s ease-in);

        @include hover-focus-active {
          text-decoration: none !important;
          cursor: pointer;
          color: $action-active-color;
        }

        & > span {
          font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
          font-size: rem(13);
          font-weight: 200;
          padding-right: rem(10);
        }
      }
    }
    //& > div

    .actions-wrapper {
      justify-content: flex-start;
      @include position;
      overflow: hidden;
      background: $action-bg-color;

      &.road-end {
        border-top-right-radius: $edit-widget-border-radius;
        border-bottom-right-radius: $edit-widget-border-radius;
      }

      .actions {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        @include position(absolute, 0, 0, 0, 0, 1);
        min-width: 100%;
        background: $action-bg-color;
        border-top: rem(1) solid $edit-widget-border-color;
        border-bottom: rem(1) solid $edit-widget-border-color;

        .action {
          border-right: rem(1) solid $edit-widget-border-color;

          &.with-text {
            padding: rem(5) rem(20);
          }

          &.active {
            background: color('white');
            color: $action-active-color;
            @include position;
            &::before {
              content: '';
              height: rem(2);
              background: color('white');
              @include position(absolute, -1px, 0, auto, 0, 1);
            }
          }

        }
      }
      //.actions
    }
    //.actions-wrapper

    .arrows {
      background: $action-bg-color;
      @include boxSize(rem(45), auto);
      flex: 0 0 rem(45);
      border: rem(1) solid $edit-widget-border-color;

      a {
        flex: 0 1 rem(45);
      }

      &.arrow-left {
        border-left: 0;
        i {
          @include transform(rotateY(180deg));
        }
      }

      &.arrow-right {
        border-top-right-radius: $edit-widget-border-radius;
        border-bottom-right-radius: $edit-widget-border-radius;
      }

    }
    //.arrows
  }
}

.icon-colors [class*="path"]:before {
  opacity: .6;
}