// ==================================================
// Form
// ==================================================

// ==================================================
// button / link / input
// ==================================================
input, button, a {
  @include plain-hover-focus {
    outline: none !important;
    @include box-shadow-none;
  }
}

// color-radio
// ----------------------------------

.color-radio {

  display: inline-block;
  @include boxSize(rem(24));
  border-radius: 50%;
  cursor: pointer;
  @include no-selection;

  input {
    @include boxSize(0);
    float: left;
  }

  .check {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include boxSize(rem(24));
    background: my-colors('color-6');
    background-clip: content-box;
    box-sizing: content-box;
    border: rem(2) solid transparent;
    border-radius: 50%;
    color: color('white');
    font-size: rem(16);

    i {
      opacity: 0;
      &::before {
        @include boxSize(rem(12));
      }
    }
  }

  input:checked ~ .check {
    border: rem(2) solid color('white');
    box-shadow: 0 0 rem(20) rem(0) rgba(0, 0, 0, .2);
    i {
      opacity: 1;
    }
  }

  &.white-check {
    .check {
      color: my-colors('color-18');
      box-shadow: inset 0 0 0 rem(1) my-colors('color-18');
    }
    input:checked ~ .check {
      border-color: my-colors('color-6');
    }
  }

  &.lg-size {
    @include boxSize(rem(48));

    .check {
      @include boxSize(rem(48));
      font-size: rem(24);
      i {
        &::before {
          @include boxSize(rem(12));
        }
      }
    }
  }

  &.sm-size {
    @include boxSize(rem(20));

    .check {
      @include boxSize(rem(20));
      font-size: rem(12);
      border: none;
      i {
        &::before {
          @include boxSize(rem(9));
        }
      }
    }

    input:checked ~ .check {
      border: none;
      box-shadow: 0 0 rem(15) rem(0) rgba(0, 0, 0, .2);
    }

    &.white-check {
      .check {
        box-sizing: border-box;
        border: rem(1) solid my-colors('color-18') !important;
        box-shadow: none;
      }

      input:checked ~ .check {
        border: rem(1) solid my-colors('color-18') !important;
      }
    }
  }

}

// custom medianet checkbox
// ----------------------------------

.medianet-checkbox {
  display: inline-flex;
  align-items: center;
  @include boxSize(auto);
  @include no-selection;
  color: my-colors('color-10');
  cursor: pointer;
  font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;

  input {
    @include boxSize(0);
    float: left;
  }
  .check {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include boxSize(rem(24));
    background: color('white');
    border: rem(1) solid my-colors('color-5');
    border-radius: rem(4);
    color: my-colors('color-6');
    font-size: rem(24);
    margin: 0 rem(10) 0 0;

    i {
      opacity: 0;
    }
  }

  input:checked ~ .check {
    border: rem(1) solid my-colors('color-6');
    color: my-colors('color-6');
    i {
      opacity: 1;
    }
  }

  &.sm-size {
    .check {
      @include boxSize(rem(20));
      font-size: rem(20);
    }
  }

  .icon {
    @include boxSize(rem(32), auto);
    margin-left: rem(10);
  }
}

// img checkbox toggle between two img
// ----------------------------------
.img-checkbox {
  @include boxSize(rem(32));
  @include position;
  cursor: pointer;
  input {
    @include boxSize(0);
    float: left;
  }
  .img-check-mark {
    @include position(absolute, 0, 0, 0, 0, 1);
    @include boxSize(rem(33), auto);
    &.default {

    }

    &.selected {
      z-index: -1;
      opacity: 0;
    }

  }

  input:checked ~ .img-check-mark {
    &.default {
      z-index: -1;
      opacity: 0;
    }

    &.selected {
      z-index: 1;
      opacity: 1;
    }
  }
}

// custom select (blank) : no bg different arrow
// overriding bootstrap select
// ----------------------------------
.bootstrap-select {
  .dropdown-toggle {
    background: color('white');
    color: my-colors('color-11');
    @include plain-hover-focus {
      outline: none !important;
      box-shadow: none !important;
    }
    .filter-option-inner {
      padding-right: rem(20);
    }
  }
  small {
    color: my-colors('color-11');
    font-family: 'NHaasGroteskDSPro-45Lt', sans-serif;
    font-size: rem(13);
  }

  .dropdown-menu {
    border-radius: 0;
    .dropdown-item {
      padding: rem(10) rem(20);
      @include transition(all .2s ease-out);
      &.active, &:active {
        background: my-colors('color-6');
      }
      @include hover-focus {
        background: my-colors('color-7');
        color: color('white');
        small {
          color: rgba(color('white'), .5) !important;
        }
      }
    }
  }

  .dropdown-toggle {
    &::after {
      content: '\f2f9';
      border: none;
      display: inline;
      font-family: 'Material-Design-Iconic-Font', sans-serif;
      font-size: rem(20);
      font-weight: 600;
      vertical-align: baseline;
      @include position(absolute, 50%, rem(15), auto, auto, 1);
      $x: calc(-50% + 2px);
      @include transform(translateY($x));
    }
  }

  &.dropup {
    .dropdown-toggle {
      &::after {
        content: '\f2fc';
      }
    }
  }

  &.blank-select {
    .dropdown-toggle {
      background: transparent !important;
      border: none;
      border-radius: 0;
      line-height: 1;
      padding: rem(10);
    }
  }

}

// form Panier
// ----------------------------------

// custum radion button

.container_radio {
  .custom_radio {
    margin-left: rem(20);
    &:first-child {
      margin-left: 0;
    }
  }
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: rem(32);
  cursor: pointer;
  line-height: rem(24);
  display: inline-block;
  font-size: rem(17);
  font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
  color: my-colors('color-10');
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: rem(24);
  height: rem(24);
  border: rem(1) solid my-colors('color-6');
  border-radius: 100%;
  background: white;
}

[type="radio"]:not(:checked) + label:before {
  border: rem(1) solid my-colors('color-5');
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: rem(12);
  height: rem(12);
  background: my-colors('color-6');
  position: absolute;
  top: rem(6);
  left: rem(6);
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

// END custum radion button

// custum checkbox button
.styled-checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;

  }
  & + label:before {
    content: '';
    margin-right: rem(20);
    display: inline-block;
    vertical-align: text-top;
    width: rem(24);
    height: rem(24);
    background: white;
    border: rem(1) solid my-colors('color-6');
    border-radius: rem(4);
  }
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }
  &:checked + label:after {
    content: '';
    position: absolute;
    left: rem(3);
    top: rem(5);
    background: my-colors('color-6');
    width: rem(19);
    height: rem(14);
    background: url('../images/png/checked.png') no-repeat center center;
  }
}

// Demo-only styles
// --------------
// END custum checkbox button

.form_panier {
  margin-top: rem(60);
  .title_form {
    font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
  }
  .box_form {
    background: my-colors('color-2');
    padding: rem(32) rem(30) rem(35);
    border-radius: $border-radius;
    .title_form {
      font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
    }

    .custom_delais_radio {
      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
        left: calc(50% - 12px);
      }
      [type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after {
        left: calc(50% - 6px);
      }
      label {
        padding-top: rem(44);
        text-align: center;
        padding-left: 0;
        .container_delais {
          .top_container_delais {
            font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
            font-size: rem(15);
            color: my-colors('color-10');
            margin-bottom: rem(3);
            span {
              color: my-colors('color-8');
            }
          }
        }
        .bottom_container_delais {
          font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
          font-size: rem(15);
          color: my-colors('color-10');
          margin: 0;
        }
      }
    }
  }

}
.form-group {
  label {
    font-size: rem(15);
    color: my-colors('color-10');
    font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
    width: 100%;
    margin-bottom: rem(10);
  }
  .form-control {
    font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
    font-size: rem(15);
    display: block;
    height: rem(44);
    width: 100%;
    background-color: my-colors('color-1');
    background-clip: padding-box;
    border: rem(1) solid my-colors('color-4');
    .dropdown-toggle::after {
      color: #212121;
    }
    &:focus{
      color: #495057;
      border-color: my-colors('color-5');
      outline: 0;
      @include box-shadow-none;
    }
    &input{
      height: rem(44);
    }
    &#cart_body{
      height:rem(210);
    }

  }
}
// word checkbox
// -----------------------------

.word-checkbox {
  @include position;
  display: inline-flex;
  align-items: center;
  margin-right: rem(10);
  padding-bottom: rem(13);

  @include hover-focus {
    cursor: pointer;
  }
  span {
    display: inline-block;
    text-align: center;
    background: my-colors('color-3');
    color: my-colors('color-11');
    padding: rem(14) rem(15);
    border-radius: rem(30);
    @include transition;
    @include no-selection;

    @include hover-focus {
      background: my-colors('color-4');
    }
  }

  input {
    @include position(absolute);
    @include boxSize(0);
    opacity: 0;
  }

  input:checked + span {
    box-shadow: 0 0 0 rem(2) my-colors('color-7');
    background: color('white');
    color: my-colors('color-7');
  }

}