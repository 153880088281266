// ==================================================
// ===== Editeur page                           =====
// ==================================================

.container_editeur {
  width: 100%;
  min-height: 100vh;
  background: #f5f5f5;
  .left_col_editeur {
    background: #130c25;
    @include position(fixed, 0, auto, auto, 0, 9999);
    @include boxSize(rem(80), 100%);
    .logo_editeur {
      @include boxSize(100%, rem(60));
      text-align: center;
    }
    .container_left_col {
      position: relative;
      z-index: 99;
      background: #130c25;
      width: 100%;
      height: 100%;
      .left_elem_editeur {
        @include opacity(0.4);
        cursor: pointer;
        @include transition_medianet(all 0.5s ease 0s);
        margin-bottom: rem(35);
        .text_ico_left {
          display: block;
          width: 100%;
          color: my-colors('color-1');
          font-size: rem(11);
          margin-top: rem(14);
        }
        &:hover, &.active {
          @include opacity(1);
        }
      }

    }
    .slide_element_editeur {
      background: white;
      box-shadow: 3.995px -0.209px 10px 0 rgba(0, 0, 0, 0.05);
      width: rem(330);
      //height: 100%;
      overflow: auto;
      height: calc(100vh - #{rem(110)});
      position: fixed;
      top: rem(110);
      left: rem(-260);
      @include transition_medianet;
      &.slide_left {
        left: rem(80);
      }
      .remove_slide {
        cursor: pointer;
        @include position(absolute, rem(30), rem(30), auto, auto, 99);
      }
      .title_text_slide {
        font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
        margin-bottom: rem(35);
      }
      .box-inputfile {
        .title_download_image {
          font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
          margin-bottom: 0;
        }
        .txt_download_image {
          font-size: rem(13);
          line-height: rem(16);
          font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
        }
      }
      .btn_medianet {
        font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
        width: 100%;
        margin-bottom: rem(20);
      }
      .container_form_editeur {
        flex-flow: row wrap;
        justify-content: space-between;
        .box_form_editeur {
          cursor: pointer;
          flex-flow: column;
          margin-bottom: rem(10);
          background: my-colors('color-2');
          border: rem(2) solid my-colors('color-2');
          @include boxSize(rem(80), rem(80));
          @include border-radius;
          align-items: center;
          justify-content: center;
          @include transition;
          .caption_form_editeur {
            color: my-colors('color-7');
            font-size: rem(11);
            display: block;
            width: 100%;
            text-align: center;
          }
          .picto_form_editeur {
            @include boxSize(rem(32), rem(32));
            &.active {
              @include transition;
            }
          }
          .picto_rectangle {
            background: url('../images/png/picto_rectangle.png') no-repeat center center;
            &.active {
              background: url('../images/png/picto_rectangle_active.png') no-repeat center center;
            }
          }
          .picto_cercle {
            background: url('../images/png/picto_cercle.png') no-repeat center center;
            &.active {
              background: url('../images/png/picto_cercle_active.png') no-repeat center center;
            }
          }
          .picto_ligne {
            background: url('../images/png/picto_ligne.png') no-repeat center center;
            &.active {
              background: url('../images/png/picto_ligne_active.png') no-repeat center center;
            }
          }
          .picto_etoile {
            background: url('../images/png/picto_etoile.png') no-repeat center center;
            &.active {
              background: url('../images/png/picto_etoile_active.png') no-repeat center center;
            }
          }
          .picto_fleche {
            background: url('../images/png/picto_fleche.png') no-repeat center center;
            &.active {
              background: url('../images/png/picto_fleche_active.png') no-repeat center center;
            }
          }
          .picto_tableau {
            background: url('../images/png/picto_tableau.png') no-repeat center center;
            &.active {
              background: url('../images/png/picto_tableau_active.png') no-repeat center center;
            }
          }
          .picto_graphformes {
            background: url('../images/png/picto_formes_graphique.png') no-repeat center center;
            &.active {
              background: url('../images/png/picto_formes_graphique_active.png') no-repeat center center;
            }
          }
          .picto_graphcadres {
            background: url('../images/png/picto_cadres.png') no-repeat center center;
            &.active {
              background: url('../images/png/picto_cadres_active.png') no-repeat center center;
            }
          }
          .picto_graphicones {
            background: url('../images/png/picto_icone.png') no-repeat center center;
            &.active {
              background: url('../images/png/picto_icone_active.png') no-repeat center center;
            }
          }
          .picto_graphtreseaux {
            background: url('../images/png/picto_reseaux.png') no-repeat center center;
            &.active {
              background: url('../images/png/picto_reseaux_active.png') no-repeat center center;
            }
          }
          .picto_graphautres {
            background: url('../images/png/picto_autresgraph.png') no-repeat center center;
            &.active {
              background: url('../images/png/picto_autresgraph_active.png') no-repeat center center;
            }
          }
          .picto_imagerecent {
            background: url('../images/png/picto_recent_image.png') no-repeat center center;
            &.active {
              background: url('../images/png/picto_recent_image_active.png') no-repeat center center;
            }
          }
          .picto_myimages {
            background: url('../images/png/picto_images.png') no-repeat center center;
            &.active {
              background: url('../images/png/picto_images_active.png') no-repeat center center;
            }
          }
          .picto_imagebiblio {
            background: url('../images/png/picto_bibliotheque.png') no-repeat center center;
            &.active {
              background: url('../images/png/picto_bibliotheque_active.png') no-repeat center center;
            }
          }
          &.active {
            border: rem(2) solid #d2cae4;
          }
        }
      }
      .box_slide_forms {
        display: none;
        .title_box_form {
          font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
          padding: rem(12) rem(30);
          margin-top: rem(40);
          margin-bottom: 0;
          background: my-colors('color-2');
          border-bottom: rem(1) solid #f1eff1;
          width: calc(100% + 60px);
          margin-left: rem(-30);
        }
        .container_nbr_col {
          margin-top: rem(22);
          .caption_nbr_col {
            color: my-colors('212121');
            font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
            display: block;
            width: 100%;
            margin-bottom: rem(9);
          }
          .box_nbr_col {
            align-items: center;
            .button_minus_plus {
              @include boxSize(rem(26), rem(26));
              border-radius: 50%;
              border: 1px solid #cdc4d2;
              text-align: center;
              display: inline-block;
              padding-top: rem(3);
              padding-left: rem(1);
              cursor: pointer;
              @include transition;
              &:hover {
                border-color: my-colors('color-6');
              }
            }
            .quantity_tab {
              @include boxSize(rem(50), rem(32));
              border: 1px solid #cdc4d2;
              border-radius: rem(8);
              display: inline-block;
              margin: 0 rem(8);
              padding: 0 rem(5) 0 rem(10);
              color: my-colors('color-10');
              font-size: rem(17);
              text-align: left;
              font-family: 'NHaasGroteskDSPro-65Md', sans-serif;
              -moz-appearance: textfield;
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
              }
            }

          }
        }
        .box_fleches{
          flex-flow: row wrap;
          justify-content: space-between;
          .box_form_fleche{
            align-items: center;
            justify-content: center;
            flex-flow: column;
            cursor: pointer;
            .caption_form_fleche{
              font-size:rem(13);
              color: my-colors('color-5');
              font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
              display: block;
              width: 100%;
              text-align: center;
              @include transition;
            }
            .picto_form_fleche {
              @include boxSize(rem(32), rem(32));
              &.active {
                @include transition;
              }
            }
            .picto_fleche_left {
              background: url('../images/png/fleche_left.png') no-repeat center center;
              &.active {
                background: url('../images/png/fleche_left_active.png') no-repeat center center;
              }
            }
            .picto_fleche_top {
              background: url('../images/png/fleche_top.png') no-repeat center center;
              &.active {
                background: url('../images/png/fleche_top_active.png') no-repeat center center;
              }
            }
            .picto_fleche_bottom {
              background: url('../images/png/fleche_bottom.png') no-repeat center center;
              &.active {
                background: url('../images/png/fleche_bottom_active.png') no-repeat center center;
              }
            }
            .picto_fleche_right {
              background: url('../images/png/fleche_right.png') no-repeat center center;
              &.active {
                background: url('../images/png/fleche_right_active.png') no-repeat center center;
              }
            }
            &.active{
              .caption_form_fleche{
                color: my-colors('color-6');
              }
              .picto_fleche_left {
                background: url('../images/png/fleche_left_active.png') no-repeat center center;
               }
              .picto_fleche_top {
                background: url('../images/png/fleche_top_active.png') no-repeat center center;
              }
              .picto_fleche_bottom {
                background: url('../images/png/fleche_bottom_active.png') no-repeat center center;
              }
              .picto_fleche_right {
                background: url('../images/png/fleche_right_active.png') no-repeat center center;
              }
            }
          }
        }
        .containerbtn_medianet {
          .btn_medianet {
            width: auto;
          }
        }
        .box_slide_graphs {
          flex-flow: row wrap;
          justify-content: space-between;
          //height: calc(100vh - 600px);
          overflow: auto;
          .title_box_graph {
            width: 100%;
            color: my-colors('color-5');
            font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
            margin-bottom: rem(15);
            margin-top: rem(12);
          }
          .container_box_graph {
            align-items: center;
            justify-content: center;
            display: flex;
            flex-flow: column;
            @include boxSize(rem(125), rem(125));
            border: rem(1) solid #e1dee2;
            margin-bottom: rem(9);
            border-radius: rem(8);
            @include transition();
            cursor: pointer;
            &.active{
              border: rem(1) solid my-colors('color-6');
            }
          }
          .container_box_biblio {
            align-items: center;
            justify-content: center;
            display: flex;
            flex-flow: column;
            @include boxSize(rem(125), rem(125));
            overflow: hidden;
            margin-bottom: rem(9);
            border-radius: rem(8);
          }
          .container_box_myimages {
            align-items: center;
            justify-content: center;
            display: flex;
            flex-flow: column;
            @include boxSize(rem(80), rem(80));
            border: rem(1) solid #e1dee2;
            margin-bottom: rem(9);
            border-radius: rem(8);
            &.img_download_editeur {
              overflow: hidden;
              border: none;
              background: my-colors('color-2');
            }
          }
        }
        .form_search_biblio {
          position: relative;
          margin-bottom: rem(19);
          .search-submit {
            border: none;
            border-radius: 0;
            background: url(../images/png/picto_search.png) no-repeat 0 0;
            @include boxSize(rem(16), rem(16));
            padding: 0;
            cursor: pointer;
            position: absolute;
            top: rem(15);
            right: rem(17);
            outline: none;
            text-indent: -5000px;
          }
          .form-control {
            background: my-colors('color-2');
            &::-webkit-input-placeholder {
              color: my-colors('color-5');
              font-size: rem(27);
              font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
            }

            &:-ms-input-placeholder {
              color: my-colors('color-5');
              font-size: rem(15);
              font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
            }

            &::placeholder {
              color: my-colors('color-5');
              font-size: rem(15);
              font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
            }
          }
        }
      }
    }
  }
  .right_col_editeur {
    width: calc(100% - 80px);
    margin-left: rem(80);
    .row_top_editeur {
      @include boxSize(auto, rem(60));
      background: #1f1635;
      align-items: center;
      @include position(fixed, 0, 0, 0, rem(80), 10);
      .text_top_editeur {
        margin: 0;
        color: white;
        font-size: rem(17);
        font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
        span {
          color: #b5b4b5;
          font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
        }
      }
      .bloc_login_editeur {
        padding-right: rem(28);

        .connexion_header {
          color: white;
          font-size: rem(15);
          font-family: 'NHaasGroteskDSPro-65Md', sans-serif;
          display: flex;
          align-items: center;

          .picto_connexion {
            display: inline-block;
            margin-right: rem(20);
            @include boxSize(rem(14), rem(16));
            background: url('../images/png/icon_connexion.png') no-repeat center center;
          }
          .account_header {
            font-size: rem(13);
            margin-left: rem(12);
            color: #b5b4b5;
            font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
            @include transition_medianet(all 0.5s ease 0s);
          }
          &:hover {
            color: my-colors('color-6');
            text-decoration: none;
            .account_header {
              color: my-colors('color-6');
              @include transition_medianet(all 0.5s ease 0s);
            }
          }
        }
        .btn_editeur {
          display: inline-block;
          width: auto;
          border-radius: calc(#{$border-radius} * 2);
          border: 1px solid #362857;
          background: transparent;
          text-align: center;
          color: #dcd5df;
          font-size: rem(13);
          font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
          padding: rem(10) rem(13);
          margin-left: rem(27);
          outline: none;
          @include transition_medianet;
          &:hover {
            background: #362857;
          }
        }
      }
    }
    .row_middle_editeur {
      @include boxSize(auto, rem(50));
      background: white;
      border-bottom: 1px solid #e9e9e9;
      align-items: center;
      @include position(fixed, rem(60), 0, 0, rem(80), 10);
      .bloc_preview_editeur {
        .name_doc_editeur {
          color: my-colors('color-12');
          display: inline-block;
          margin: 0;
          border-right: 1px solid #e1dee2;
        }
        .prev_doc_editeur {
          color: my-colors('color-6');
          display: inline-block;
          text-decoration: none;
          cursor: pointer;
          .prev_doc_caption {
            padding-left: rem(6);
          }
          &:hover, &:hover span {
            color: my-colors('color-6');
          }
        }
      }
      .bloc_zoom_editeur {
        > span {
          display: inline-block;
        }
        .zoom_minus, .zoom_plus {
          color: my-colors('color-6');
          font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
          cursor: pointer;
          @include no-selection;

          &:hover {
            color: my-colors('color-7');
          }
        }
        .zoom_apercu {
          display: inline-flex;
          @include position;
          input {
            display: inline-flex;
            @include boxSize(rem(60), rem(32));
            background: my-colors('color-23');
            color: my-colors('color-10');
            font-size: rem(13);
            font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
            border-radius: calc(#{$border-radius} * 2);
            border: 1px solid #e3dfe7;
            align-items: center;
            padding: rem(8) rem(12);
          }
          span {
            font-size: rem(12);
            @include position(absolute, 50%, rem(10), auto, auto, 1);
            @include transform(translateY(-50%));
            @include no-selection;
            pointer-events: none;

          }

        }

      }
      .bloc_save_editeur {
        .toolbar_button {
          align-items: center;
          margin-left: rem(20);
          padding-left: rem(20);
          border-left: 1px solid #e1dee2;
          cursor: pointer;
          .toolbar_button_icon {
            margin-right: rem(10);
          }
          .toolbar_button_caption {
            color: my-colors('color-7');
            font-family: 'NHaasGroteskDSPro-65Md', sans-serif;
          }
          &:first-child {
            border-left: none;
            padding-left: 0;
          }
          &:hover, &:hover span {
            color: my-colors('color-6');
          }
        }
        .finish_button {
          height: rem(50);
          background: my-colors('color-6');
          padding: 0 rem(28);
          color: #f9f9f9;
          text-transform: uppercase;
          font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
          border: none;
          font-size: rem(13);
          margin-left: rem(20);
          letter-spacing: rem(1);
          &:hover {
            background: my-colors('color-7');
          }
        }
      }
    }
    .row_face_editeur {
      margin-top: rem(110);
      .btn_face_editeur {
        background: #f0eef2;
        border: 1px solid #e1dee2;
        color: my-colors('color-12');
        font-size: rem(13);
        font-family: 'NHaasGroteskDSPro-65Md', sans-serif;
        text-align: center;
        padding: rem(13) rem(30);
        outline: none;
        &:first-child {
          border-radius: rem(3) 0 0 rem(3);
          border-right: none;
        }
        &:last-child {
          border-radius: 0 rem(3) rem(3) 0;
        }
        &.active, &:hover {
          background: white;
          color: my-colors('color-6');
        }
      }
    }
    .container_bloc_editeur {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      height: calc(100vh - #{rem(110)} - (#{rem(30)} * 2) - #{rem(41)} );
      overflow: auto;padding: rem(30);

      .bloc_editeur {
        background: color('white');
        box-shadow: 0 rem(1) 0 0 rgba(0, 0, 0, 0.1), 0 rem(5) rem(5) 0 rgba(0, 0, 0, 0.1);
        padding: rem(30);
        overflow: hidden;
        @include transition;
        @include position;

        .securite_bloc_editeur {
          border: 1px solid #e1dee2;
          height: 100%;
        }
      }
    }
  }
}

.ui-widget.ui-widget-content {
  width: calc(100% - #{rem(50)});
  margin-left: rem(20);
  height: rem(6);
  border: none;
  background: my-colors('color-4');
  .ui-slider-handle {
    @include boxSize(rem(26), rem(26));
    border-radius: 50%;
    background: white;
    border: 1px solid #cec5d3;
    outline: none;
    top: rem(-11);
  }
  .ui-widget-header {
    background: my-colors('color-15');
  }
}

.container_finish_save {
  align-items: center;
  justify-content: center;
  height: 100%;
  .box-save-editeur,
  .box-finish-editeur,
  .box-preview-editeur {
    display: none;
    .reset_overlay {
      color: my-colors('color-6');
      font-size: rem(17);
      cursor: pointer;
      font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
    }
    #name_doc_editeur {
      border: none;
      border-bottom: rem(1) solid #e1dee2;
      width: 640px;
      text-align: center;
      color: my-colors('color-10');
      font-size: rem(27);
      font-family: 'NHaasGroteskDSPro-95Blk', sans-serif;

      @include placeholder {
        color: my-colors('color-5');
        font-size: rem(27);
        font-family: 'NHaasGroteskDSPro-95Blk', sans-serif;
      }
    }
  }
  .box-preview-editeur {
    padding: 0 rem(80);
    width: 100%;
    .row_top_preview {
      padding-bottom: rem(15);
      margin-bottom: rem(65);
      border-bottom: rem(1) solid #e4e1e5;
      align-items: center;
      .title_preview {
        margin-bottom: 0;
        font-family: 'NHaasGroteskDSPro-95Blk', sans-serif;
      }
      .container_btn_top_preview {
        .btn_medianet {
          font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
          i {
            margin-right: rem(5);
          }
        }
      }
    }
    .row_bottom_preview {
      .col-12 {
        padding: 0;
        .block_preview {
          background: #f5f5f5;
          .preview_image {
            box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
            img {
              width: 100%;
            }
          }

        }
        .row_btn_preview {
          .caption_bottom_preview {
            color: my-colors('color-11');
            font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
            font-size: rem(17);
            margin-right: rem(24);
          }
          .link_bottom_preview {
            color: my-colors('color-6');
            font-family: 'NHaasGroteskDSPro-65Md', sans-serif;
            font-size: rem(15);
            text-decoration: none;
            i {
              margin-left: rem(5);
            }
            &:hover {
              color: my-colors('color-6');
            }
          }
        }
      }
    }
    .row_desc_preview {
      .title_desc_preview {
        color: my-colors('color-6');
        font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
        font-size: rem(17);
      }
      .caption_desc_preview {
        font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
        line-height: rem(19);
      }
    }
  }
  &.aligns-top-items {
    align-items: inherit;
  }
}

.besoin_aide {
  @include boxSize(rem(90), rem(90));
  border-radius: 50%;
  background: my-colors('color-6');
  color: white;
  text-align: center;
  cursor: pointer;
  @include position(absolute, auto, rem(80), rem(80), auto, 9999);
  @include transition;
  box-shadow: 10.607px 10.607px 16px 0 rgba(0, 0, 0, 0.22), 0 2px 10px 0 rgba(0, 0, 0, 0.17);
  .caption_besoin_aide {
    font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
    font-size: rem(13);
    margin: 0;
    line-height: rem(16);
    span {
      font-size: rem(17);
      font-family: 'NHaasGroteskDSPro-65Md', sans-serif;
    }
  }
  &:hover {
    background: my-colors('color-7');
  }
}

.overlay {
  position: fixed;
  background: rgba(255, 255, 255, 0.95);
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, height .35s;
  z-index: 100000;
  overflow: auto;
}

.overlay.open {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.overlay.opacity_bg {
  background: rgba(255, 255, 255, 1);
}

.overlay.open li, .overlay.open .titre_menu_h3 {
  animation: fadeInRight .5s ease forwards;
  animation-delay: .35s;
}

.overlay.open li:nth-of-type(2) {
  animation-delay: .4s;
}

.overlay.open li:nth-of-type(3) {
  animation-delay: .45s;
}

.overlay.open li:nth-of-type(4) {
  animation-delay: .50s;
}

.overlay.open li:nth-of-type(5) {
  animation-delay: .55s;
}

.overlay.open li:nth-of-type(6) {
  animation-delay: .60s;
}

.overlay.open li:nth-of-type(7) {
  animation-delay: .65s;
}

.overlay.open li:nth-of-type(8) {
  animation-delay: .70s;
}

.overlay div.block_header {
  position: relative;
  text-align: left;
}

.overlay div.block_header ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  height: 100%;
}

.overlay div.block_header ul ul {
  padding-left: 20px;
}

.overlay div.block_header ul ul a {
  font-size: 15px;
  line-height: 23px;
}

.block_header .titre_menu_h3 i {
  display: none;
}

.overlay div.block_header ul li, .overlay.open .titre_menu_h3 {
  display: block;
  position: relative;
  opacity: 0;
}

.overlay ul li a {
  display: block;
  position: relative;
  color: #090512;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
}

.overlay div.block_header ul li a:hover {
  color: #8067b7;
  text-decoration: none;
}

/*** custum input file ***/
.box-inputfile {
  text-align: center;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
  /* 10px 20px */
}

.inputfile:focus + label,
.inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  /* pointer-events: none; */
  /* in case of FastClick lib use */
}

.inputfile + label img {
  vertical-align: middle;
  fill: currentColor;
}

/*************** SCROLLBAR BASE CSS ***************/

.scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
  position: relative;
  min-height: rem(200);
  height: calc(100vh - #{rem(540)});
}

.scroll-wrapper > .scroll-content {
  border: none !important;
  box-sizing: content-box !important;
  height: auto;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none !important;
  overflow: scroll !important;
  padding: 0;
  position: relative !important;
  top: 0;
  width: auto !important;
}

.scroll-wrapper > .scroll-content::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.scroll-element {
  display: none;
}

.scroll-element, .scroll-element div {
  box-sizing: content-box;
}

.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
  display: block;
}

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
  cursor: default;
}

.scroll-textarea {
  border: 1px solid #cccccc;
  border-top-color: #999999;
}

.scroll-textarea > .scroll-content {
  overflow: hidden !important;
}

.scroll-textarea > .scroll-content > textarea {
  border: none !important;
  box-sizing: border-box;
  height: 100% !important;
  margin: 0;
  max-height: none !important;
  max-width: none !important;
  overflow: scroll !important;
  outline: none;
  padding: 2px;
  position: relative !important;
  top: 0;
  width: 100% !important;
}

.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
  height: 0;
  width: 0;
}

/*************** SIMPLE INNER SCROLLBAR ***************/

.scrollbar-inner > .scroll-element,
.scrollbar-inner > .scroll-element div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
}

.scrollbar-inner > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.scrollbar-inner > .scroll-element.scroll-x {
  bottom: 2px;
  height: 8px;
  left: 0;
  width: 100%;
}

.scrollbar-inner > .scroll-element.scroll-y {
  height: 100%;
  right: 10px;
  top: 0;
  width: 10px;
}

.scrollbar-inner > .scroll-element .scroll-element_outer {
  overflow: hidden;
}

.scrollbar-inner > .scroll-element .scroll-element_outer,
.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=30);
  opacity: 0.3;
}

.scrollbar-inner > .scroll-element .scroll-element_track {
  background-color: #1a1919;
}

.scrollbar-inner > .scroll-element .scroll-bar {
  background-color: #f7f5f8;
  filter: alpha(opacity=100);
  opacity: 1;
}

.scrollbar-inner > .scroll-element:hover .scroll-bar {
  background-color: #f7f5f8;
  filter: alpha(opacity=100);
  opacity: 1;
}

.scrollbar-inner > .scroll-element.scroll-draggable .scroll-bar {
  background-color: #f7f5f8;
  filter: alpha(opacity=100);
  opacity: 1;
}

/* update scrollbar offset if both scrolls are visible */

//.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
//  left: -12px;
//}
//
//.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
//  top: -12px;
//}
//
//.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
//  left: -12px;
//}
//
//.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
//  top: -12px;
//}

.scrollbar-inner {
  & > .scroll-element {
    &.scroll-x {
      &.scroll-scrolly_visible {
        .scroll-element_track,
        .scroll-element_size {
          left: rem(-12);
        }
      }
    }

    &.scroll-y {
      &.scroll-scrolly_xisible {
        .scroll-element_track,
        .scroll-element_size {
          top: rem(-12);
        }
      }
    }
  }
}

// page loader

.page_loader {
  $progress-bar-stroke-width: rem(1);
  $progress-bar-size: rem(180);
  .loading {
    @include boxSize(rem(180));
    @include position;
    margin: auto;

    svg {
      @include boxSize($progress-bar-size);
      transform: rotate(-90deg);
    }

    .progress-bar__background {
      fill: none;
      stroke: my-colors('color-5');
      stroke-width: $progress-bar-stroke-width;
    }

    .progress-bar__progress {
      fill: none;
      stroke: my-colors('color-6');
      stroke-dasharray: 100 100;
      stroke-dashoffset: 100;
      stroke-width: $progress-bar-stroke-width;
      transition: stroke-dashoffset 1s ease-in-out;
    }

    img {
      @include position(absolute, 50%, auto, auto, 50%, 1);
      @include boxSize(auto, rem(78));
      @include transform(translate(-50%, -50%));
    }
  }

  .font-weight-bold {
    font-family: 'NHaasGroteskDSPro-75Bd', sans-serif;
    font-weight: 700;
  }
  .font-weight-light {
    font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
    font-weight: 200;
  }

  p {
    font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
    font-size: rem(13);
  }
}
////////////////////
.img_upload_progress {
  .value {
    font-size: rem(21);
    font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
    margin-bottom: rem(10);
  }
  .progress {
    @include boxSize(rem(100), rem(10));
    margin: 0 auto rem(25);
    border-radius: $border-radius;
  }
}

@include media-breakpoint-between(md, lg){
  .container_editeur .right_col_editeur .row_middle_editeur .bloc_preview_editeur .name_doc_editeur {
    margin-right:rem(10) !important;
    padding-right: rem(10) !important;
  }
  .container_editeur .right_col_editeur .row_middle_editeur .bloc_zoom_editeur .zoom_apercu {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .container_editeur .right_col_editeur .row_middle_editeur .bloc_save_editeur .toolbar_button {
    margin-left: rem(10) !important;
    padding-left: rem(10) !important;
  }
  .container_editeur .right_col_editeur .row_middle_editeur .bloc_save_editeur .toolbar_button .toolbar_button_caption {
    font-size: 13px;
  }
  .container_editeur .right_col_editeur .row_middle_editeur .bloc_save_editeur .toolbar_button .toolbar_button_icon img{
    @include boxSize(rem(16), auto);
    max-width: inherit;
  }
  .container_editeur .right_col_editeur .row_middle_editeur .bloc_save_editeur .finish_button {
    padding: 0 rem(10);
    font-size: rem(13);
    margin-left: rem(10);
  }
  .container_editeur .right_col_editeur .row_middle_editeur .bloc_zoom_editeur .zoom_minus, .container_editeur .right_col_editeur .row_middle_editeur .bloc_zoom_editeur .zoom_plus {
    font-size: 13px;
  }
}