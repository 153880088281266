// ==================================================
// mixins
// ==================================================

// font-face
// Usage:
//      => @include font-face("../fonts" , Lato-Bold-webfont, Latobold, normal);
@mixin font-face($base-url, $font-filename, $font-aliasname, $weight) {
  @font-face {
    font: {
      family: $font-aliasname;
      style: normal;
      weight: $weight;
    }
    src: url($base-url + "/" + $font-filename + ".eot");
    src: url($base-url + "/" + $font-filename + ".eot?#iefix") format('embedded-opentype'),
    url($base-url + "/" + $font-filename + ".otf") format("opentype"),
    url($base-url + "/" + $font-filename + ".woff") format("woff"),
    url($base-url + "/" + $font-filename + ".ttf") format("truetype"),
    url($base-url + "/" + $font-filename + ".svg#" + $font-filename) format("svg");
  }
}

@mixin headings($from: 1, $to: 6) {
  @for $i from $from through $to {
    h#{$i} {
      @content
    }
  }
}

@mixin prefix($name, $value) {
  -webkit-#{$name}: $value;
  -moz-#{$name}: $value;
  -ms-#{$name}: $value;
  -o-#{$name}: $value;
  #{$name}: $value;
}

@mixin opacity($value) {
  $IEValue: $value*100;
  -moz-opacity: $value;
  opacity: $value;
  filter: alpha(opacity=$IEValue);
  -ms-filter: "alpha(opacity=$IEValue)";
}

@mixin box-shadow-none {
  @include prefix(box-shadow, none !important);
}

// Transform ALL
// Usage:  
//      => @include transform(translate(0,-50%) translateZ(0px));
//         @include transform(rotate(45deg)) / @include transform(scale(0,0)) / @include transform(scale(1))
//         @include transform(scaleZ(0)) / @include transform(skew(0, 1.2deg))
@mixin transform($transforms) {
  @include prefix(transform, $transforms);
}

// placeholder

@mixin placeholder {
  &::-webkit-input-placeholder {
    /* WebKit browsers */
    @content;
  }

  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    @content;
    opacity: 1;
  }

  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    @content;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    @content;
  }
}

@mixin plain-placeholder {
  & {
    @content;
  }
  @include placeholder {
    @content;
  }
}

// Usage @include grey-filter(100%);
@mixin grey-filter($value) {
  @include prefix(filter, grayscale($value));
  filter: gray;
}

// Usage:

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin no-selection {
  -webkit-touch-callout: none;
  @include prefix(user-select, none);
}

@mixin position($position: relative, $top: auto, $right: auto, $bottom: auto, $left: auto, $z-index: auto) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  z-index: $z-index;
}

@mixin boxSize($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin transition_medianet($transition...) {
    @if length($transition) == 0 {
      @include prefix(transition, all 0.3s ease-in-out);
    } @else {
      @include prefix(transition, $transition);
    }

}