
$browser-context: 16; // Default


// Convert px to em
// Usage:
//       @include em(10);
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em
}

// Convert px to rem
// Usage:
//      => @include rem(10);
@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem
}

@function social-color($key: "facebook") {
  @return map-get($social-colors, $key);
}

@function my-colors($key: "color-1") {
  @return map-get($my-colors, $key);
}



$i: 5;
@while ($i < 100) {
  $i: $i+5;

  .m-#{$i} {
    margin: rem($i) !important;
  }

  .mx-#{$i} {
    margin-right: rem($i) !important;
    margin-left: rem($i) !important;
  }

  .my-#{$i} {
    margin-top: rem($i) !important;
    margin-bottom: rem($i) !important;
  }

  .mt-#{$i} {
    margin-top: rem($i) !important;
  }

  .mr-#{$i} {
    margin-right: rem($i) !important;
  }

  .mb-#{$i} {
    margin-bottom: rem($i) !important;
  }

  .ml-#{$i} {
    margin-left: rem($i) !important;
  }
}




///////// padding

$i: 5;
@while ($i < 100) {
  $i: $i+5;

  .p-#{$i} {
    padding: rem($i) !important;
  }

  .px-#{$i} {
    padding-right: rem($i) !important;
    padding-left: rem($i) !important;
  }

  .py-#{$i} {
    padding-top: rem($i) !important;
    padding-bottom: rem($i) !important;
  }

  .pt-#{$i} {
    padding-top: rem($i) !important;
  }

  .pr-#{$i} {
    padding-right: rem($i) !important;
  }


  .pb-#{$i} {
    padding-bottom: rem($i) !important;
  }

  .pl-#{$i} {
    padding-left: rem($i) !important;
  }

}



