// ==================================================
// Typography
// ==================================================
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

*, *:after, *:before {
  box-sizing: border-box;
}

html {
  font-size: rem($browser-context);
}

body {
  font-size: rem(15);
  font-family: $fontBody;
  font-weight: normal;
  color: $color-10;
  line-height: 1;
}

@include headings() {
  font-weight: normal;
  line-height: 1;
  font-family: $fontBody;
  color: $color-10;
  margin: 0 0 rem(30);
  padding: 0;
}

h1 {
  font-size: rem(27);
}

h2 {
  font-size: rem(25);
}

h3 {
  font-size: rem(21);
}

h4 {
  font-size: rem(17);
}

h5 {
  font-size: rem(15);
}

h6 {
  font-size: rem(13);
}

a {
  outline: 0;
  text-decoration: none;
  color: $color-15;
}

strong, b {
  font-weight: bold;
}

u {
  text-decoration: underline;
}

em, i {
  font-style: italic;
}

hr {
  height: 1px;
  width: 100%;
  clear: both;
  border: none;
  margin: 10px 0;
  background: $color-4;
}

sub {
  vertical-align: sub;
}

sup {
  vertical-align: super;
}

blockquote {
  position: relative;
  &:after {
  }
  &:before {
  }
}

cite {
  font-style: italic;
}

p {
  line-height: 1.2;
  margin-bottom: rem(20);
}

ul, ol {
  padding: 0;
  margin: 0 0 rem(20);
  list-style: none;
  ul, ol {
    margin-bottom: rem(10);
  }

  li {
    list-style: none;
    padding: 0;
    margin: 0 0 rem(10);
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.ordered-list {
    list-style: decimal;
    padding: 0 0 0 rem(25);
    li {
      list-style: decimal;
    }
  }
}

.anchored-title {
  margin: 0;
  &::after {
    content: '';
    display: block;
    height: rem(1);
    width: rem(70);
    background: my-colors('color-14');
    margin: rem(20) 0 rem(20) rem(1);
  }
}

.gray-link {
  text-decoration: underline;
  @include plain-hover-focus {
    color: my-colors('color-10');
  }

  @include hover-focus-active {
    color: my-colors('color-6');
  }
}