// Btn ======================================

.btn_medianet {
  $btn-main-value: rem(15);
  display: inline-block;
  padding: $btn-main-value calc(#{$btn-main-value} * 4);
  width: auto;
  border-radius: calc(#{$border-radius} * 2);
  border: 1px solid my-colors('color-10');
  background: transparent;
  text-align: center;
  color: my-colors('color-10');
  @include transition_medianet;

  @include media-breakpoint-down(sm) {
    padding: $btn-main-value calc(#{$btn-main-value} * 2);
  }

  &.btn_fill {
    display: block;
    width: 100%;
    padding: $btn-main-value;
  }

  @include hover-focus-active {
    background: my-colors('color-10');
    color: color('white');
    text-decoration: none;
  }

  &.btn_default {
    border-color: my-colors('color-5');
    background: color('white');
    color: my-colors('color-6');
    @include hover-focus-active {
      border-color: my-colors('color-3');
      background: my-colors('color-3');
    }
  }

  &.btn_product {
    border-color: my-colors('color-6');
    background: my-colors('color-6');
    color: color('white');
    @include hover-focus-active {
      border-color: my-colors('color-7');
      background: my-colors('color-7');
    }
  }

  &.btn_command {
    border-color: my-colors('color-8');
    background: my-colors('color-8');
    color: color('white');
    @include hover-focus-active {
      border-color: my-colors('color-9');
      background: my-colors('color-9');
    }
  }
}

.btn_file {
  @include position;
  @include hover-focus {
    cursor: pointer;
  }

  input {
    display: inline-block;
    @include boxSize(0);
    opacity: 0;
    @include position(absolute);
  }
}

// END Btn ==================================