.no-padding{
  padding-left:0 !important;
  padding-right:0 !important;
  padding-top:0 !important;
  padding-bottom:0 !important;
}
.no-padding-right{
  padding-right:0 !important;
}
img{
  max-width: 100%;
  height: auto;
}
a, button {
  @include transition_medianet(all 0.5s ease 0s);
  text-decoration: none;
}

.toggle_search_weprint {
   @include position;
  &::after {
    content:'';
    @include position(absolute, auto, 0, -1px, 0, 1);
    @include boxSize(100%, 2px);
    background: my-colors('color-2');
  }
}



//
@each $color,
$value in $my-colors {
  .bg-#{$color} {
    background-color: $value !important;
  }

  .text-#{$color} {
    color: $value !important;
  }

  .link-#{$color} {
    color: $value !important;

    @include hover-focus-active {
      color: darken($value, 15%) !important;
      text-decoration: none;
    }
  }
}